import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import App from './App.vue';
import less from "less";
import VueAwesomeSwiper from "vue-awesome-swiper/dist/vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import VueMeta from 'vue-meta'
import $ from 'jquery';
import './assets/icon/iconfont/iconfont.css'
import '../utils/rem'

// 使用juqery(挂载)
window.jQuery = $;
window.$ = $;

Vue.use(ElementUI);
Vue.use(less);
Vue.use(VueAwesomeSwiper);
Vue.use(VueMeta)
new Vue({
  el: '#app',
  router,
  render: h => h(App)
});

// 根据分辨率以及机型跳转不同端
// (function ($) {
//   var browser = {
//       versions: function () {
//           var u = navigator.userAgent, app = navigator.appVersion;
//           return {//移动终端浏览器版本信息   
//               trident: u.indexOf('Trident') > -1, //IE内核  
//               presto: u.indexOf('Presto') > -1, //opera内核  
//               webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核  
//               gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核  
//               mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端  
//               ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端  
//               android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器  
//               iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器  
//               iPad: u.indexOf('iPad') > -1, //是否iPad    
//               webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部  
//           };
//       }(),
//       language: (navigator.browserLanguage || navigator.language).toLowerCase()
//   }
//   if (browser.versions.mobile || browser.versions.ios || browser.versions.android ||
//       browser.versions.iPhone || browser.versions.iPad) {
//       // //斯坦科维奇杯只有pc版，不做判断
//       // if (window.location.href.indexOf('stankovic/') > -1) return;
//       // //没有手机版的专题
//       // var notHaveMobile = ['about_1703a', 'ea', 'fed', 'fgdx_1704', 'guess', 'index', 'notice', 'olympics-obsolete', 'onlinetrading', 'SpreadOffer', 'stankovic/index', 'sczl_1708k'];
//       // $.each(notHaveMobile, function (i, d) {
//       //     notHaveMobile[i] = 'subject/' + d + '.html';
//       // });
//       // var url = window.location.pathname.substring(1);
//       // var domain = ['//www.iejtnk.com/', '//www.jvjtjqr.com/'];
//       // if ($.inArray(url) == -1) {
//       //   window.location.href = domain[0] + url;
//       // }
//   }else{
//     var url = window.location.pathname.substring(1);
//     window.location.href = '//www.iejtnk.com/' + url;
//   }
// })(jQuery);
