import request from '../utils/axios';
import { appCode, getIMEI } from '../utils/tools'
// 测试
const textApi = 'http://testcrmapi.xs9999.com.hk';
// 线上
// const originApi = window.location.origin +'/api/proxy';
// const originApi = 'https://testwww.rlc525.com/api/proxy';
const originApi = 'https://www.rlcvipltd.net/api/proxy';
//banner图配置
export function swiperBanner(data){
  return request({
    url: originApi + '/v4/PA159',
    method: 'POST',
    data
  })
}
//图片验证码
export function verifyImgCode(data){
  return request({
    url: '/bpis/common/imgCheckSendCode',
    method: 'POST',
    data: {
      mobile: data.Mobile,
      sendType: '1',
      answer: data.ImgCode,
    },
    headers: {
      appcode: data.Appcode || '9999',
      imei: data.Token,
    },
  })
}
//快捷注册
export async function quickRegister(data){
  data.ClientId = getIMEI();
  data.IsMt5 = true;
  const ipInfo = await getIpConfig();
  data = {...ipInfo, ...data};
  return request({
    url: originApi + '/v4/U101',
    method: 'POST',
    data,
    headers: {
      platform: 2
    }
  })
}
// 获取live800
export function getLive800(params) {
  return request({
    url: originApi + '/v4/ACV100',
    method: 'get',
    params
  })
}
// 获取App渠道链接
export function getChannelInfo(code) {
  return request({
    url:'bpis/common/getChannelInfo',
    method: 'get',
    headers : {
      'appcode': code
    }
  })
}

// 根据key值获取配置信息
export function getConfigurationValue(data) {
  return request({
    url:'bpis/common/getJfConfigurationValue',
    method: 'POST',
    data
  })
}

// crm埋点
export function maidianOpenreal (data) {
  return request({
    // url:'http://kafka.crm.lan/scada/collect',  // 测试
    url:'https://crm.etescape.com:12343/maidian/scada/collect',  // 生产环境
    method: 'POST',
    data: [
      {
        sourceid: 1,
        usertype: data.usertype ? data.usertype : 2,
        clientid: getIMEI(),
        eventtime: new Date().getTime(),
        detail: {
          ...data.detail,
          channelname: '手动输入',
          backurl: window.location.href,
          currenturl: window.location.href,
          account: data.account,
          accounttype: data.accounttype,
          packageid: data.packageid || appCode()['v'] || appCode()['channel'],
          medium: appCode()['utm_medium'] || undefined, //媒介
          keyworld: appCode()['utm_term'] || undefined, //关键字
          plan: appCode()['utm_campaign'] || undefined, //计划
          unit: appCode()['utm_content'] || undefined, //单元
        },
        eventname: data.eventname,
        eventdescribe: data.eventdescribe,
        eventtype: data.eventtype || 'visit',
      }
    ]
  })
}

// 获取IP信息
export async function getIpConfig() {
  const ipInfo = sessionStorage.getItem("IP");
  if(ipInfo && ipInfo!== '{}') {
    const location = JSON.parse(ipInfo);
    const arr = [location.country, 0, location.province, location.city, location.isp]
    return {
      Ip: location.ip,
      IpAddr: arr.join('|')
    }
  } else {
    try {
      const res = await getConfigurationValue({key: 'IPAPI'});
      if(!res.Data) return {}
      const ipResponse = await fetch('https://api.ipdatacloud.com/v2/query?key=' + res.Data);
      const ipData = await ipResponse.json();
      const location = ipData.data.location || {};
      sessionStorage.setItem("IP", JSON.stringify(location));
      const arr = [location.country, 0, location.province, location.city, location.isp]
      return {
        Ip: location.ip,
        IpAddr: arr.join('|')
      }
    } catch(e) {
      return {}
    }
  }
}