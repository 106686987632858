import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Index',
      component: () => import('../components/Index'),
    },
    {
      path: '/Details',
      name: 'Details',
      component: () => import('../components/Details'),
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import('../components/Layout'),
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('../components/Contactus'),
    },
    {
      path: '/Aboutus',
      name: 'Aboutus',
      component: () => import('../components/Aboutus'),
    },
    {
      path: '/Appdownload',
      name: 'Appdownload',
      component: () => import('../components/Appdownload'),
    },
    {
      path: '/Application',
      name: 'Application',
      component: () => import('../components/Application'),
    },
    {
      path: '/Functiong',
      name: 'Functiong',
      component: () => import('../components/Functiong'),
    },
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import('../components/Privacy'),
    },
    {
      path: '/activity/autumnOct/index',
      name: 'autumnOctPC',
      component: () => import('../components/activity/autumnOct/index'),
    },
    {
      path: '/activity/autumnOct/wap',
      name: 'autumnOctWap',
      component: () => import('../components/activity/autumnOct/wap'),
    },
    {
      path: '/activity/startPage/wap',
      name: 'startPageWap',
      component: () => import('../components/activity/startPage/wap'),
    },
    {
      path: '/activity/voucher/wap',
      name: 'voucherWap',
      component: () => import('../components/activity/voucher/wap'),
    },
    {
      path: '/activity/czzj_6357/wap',
      name: 'czzj_6357_wap',
      component: () => import('../components/activity/czzj_6357/wap'),
    },
    {
      path: '/activity/voucher/wapNew',
      name: 'voucherNewWap',
      component: () => import('../components/activity/voucher/wapNew'),
    }, 
    {
      path: '/activity/voucher/wap2024',
      name: 'voucher2024',
      component: () => import('../components/activity/voucher/wap2024'),
    },
    {
      path: '/activity/market/wap',
      name: 'marketWap',
      component: () => import('../components/activity/market/wap'),
    },
    {
      path: '/activity/czzj_6357/wapNew',
      name: 'czzj_6357_wapNew',
      component: () => import('../components/activity/czzj_6357/wapNew'),
    },
    {
      path: '/activity/private-b',
      name: 'private-b',
      component: () => import('../components/activity/private-b/index.vue'),
    },
    {
      path: '/activity/traderCompetition',
      name: 'traderCompetition',
      component: () => import('../components/activity/traderCompetition/index.vue'),
    },
    {
      path: '/activity/voucher/wapFut',
      name: 'voucherFut',
      component: () => import('../components/activity/voucher/wapFut'),
    },
  ],
})
