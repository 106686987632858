<template>
  <div id="box">
    <div 
      :class="navBarFixed == true ? 'navBarWrap' :''" ref="navBarFixed"
      :style="{height:this.menuHeight}">
      <div class="header">
      <div class="logo_img" @click="logoClick">
        <div>
          <div class="header-logo">
            <img v-if="!navBarFixed" id="header-img" src="../../static/common/logo.png">
            <img v-if="navBarFixed" id="header-img" src="../../static/common/logowhite.png" alt="">
          </div>
        </div>
      </div>
      <img class="menuImg" src="../../static/common/menu.png" @click="controlMenuShow" alt="">
      <van-popup
        id="drawer"
        v-model="show"
        closeable
        close-icon-position="top-right"
        position="right"
        @opened="openPopup"
        @click-close-icon="closerPopup"
        :style="{ height: '100%', width: '100%' }">
        <div class="menuBox">
          <div class="logoBox">
            <img v-if="!navBarFixed" id="header-img" src="../../static/common/logo.png">
            <img v-if="navBarFixed" id="header-img" src="../../static/common/logowhite.png" alt="">
          </div>
          <ul>
            <li :class="controlMenuClass == 1 ? 'menuClassRed' : '' " @click="handleSelect(1)">首页</li>
            <li :class="controlMenuClass == 2 ? 'menuClassRed' : '' " @click="handleSelect(2)">关于我们</li>
            <li :class="controlMenuClass == 3 ? 'menuClassRed' : '' " @click="handleSelect(3)">交易细则</li>
            <li :class="controlMenuClass == 4 ? 'menuClassRed' : '' " @click="handleSelect(4)">APP下载</li>
          </ul>
        </div>
      </van-popup>
      <!-- <div v-if="seen" style="float: right; display: flex;align-items: center; height: 100%;">
        <el-button type="text" style="margin-right: 20px;" class="header-title" @click="login">登录</el-button>
        <el-button type="primary" class="header-title" @click="register" round>注册</el-button>
      </div> -->
    </div>
    <!-- <div id="back_to_top" ref="btn" @click="backTop" style="display: none;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="../assets/other/launch.png" style="height: 45px;width: 35px" />
    </div> -->
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import { Popup } from "vant";
  import 'vant/lib/index.css';
  Vue.use(Popup);
  export default {
    name: "Layout",
    // props:['getSessinKey'],
    data() {
      return {
        activeIndex: '1',
        company: {
          qrcode: require("../../static/other/公众号二维码.jpg"),
        },
        scroll: '',
        timer: '',
        seen: true,
        currentMenu: '首页',
        navBarFixed: false,
        windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
        show: false,
        controlMenuClass:1,
        menuHeight:'auto',
      };
    },
    created(){
      this.controlMenuClass = sessionStorage.getItem('key');
      this.$emit('getKey', this.activeIndex);
    },
    mounted() {
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.windowWidth = window.fullWidth; // 宽
        })()
      };
    },
    watch: {
      windowWidth (val) {
        let that = this;
        console.log("实时屏幕宽度：",val, that.windowWidth );
      }
    },
    methods: {
      // 打开弹出层且动画结束后触发
      openPopup(){
        this.$refs.navBarFixed.style.height = '100%'
        // document.getElementsByClassName("navBarWrap")[0].style.height = '100%';
      },
      // 点击关闭弹出层图片时触发
      closerPopup(){
        // document.getElementsByClassName("navBarWrap")[0].style.height = '';
        this.$refs.navBarFixed.style.height = ''
      },
      // 控制mune弹出层
      controlMenuShow(){
        this.show = true;
        // console.log(document.getElementsByClassName("van-icon")[0]);
      },
      handleSelect(key) {
        this.$emit('getKey', key);
        sessionStorage.setItem('key',key);
        // this.getSessinKey(key);
        switch (key) {
          case 1:
            this.controlMenuClass = key;
            this.$router.push({
              name: "Index"
            });
            this.show = false;
            break;
          case 2:
            this.controlMenuClass = key;
            this.$router.push({
              name:'Aboutus'
            })
            this.show = false;
            break;
          case 3:
            this.controlMenuClass = key;
            this.$router.push({
              name: "Details"
            });
            this.show = false;
            break;
          case 4:
            this.controlMenuClass = key;
            this.$router.push({
              name: "Appdownload"
            });
            this.show = false;
            break;
        }
      },
      handleScroll() {
        this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
        // if(this.scroll > 600) { //判断滚动后高度超过400px,就显示
        //   this.$refs.btn.style.display = 'block';
        // } else {
        //   this.$refs.btn.style.display = 'none'
        // }
        if(this.scroll > 90 ){
          this.navBarFixed = true;
          setTimeout(()=>{
            document.getElementsByClassName("navBarWrap")[0].style.height = '';
          },50)
        }else{
          this.navBarFixed = false;
        }
      },
      backTop() { //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer)
          }
        }, 10)
      },
      logoClick() {
        this.$router.push({
          name: "Index"
        });
        this.currentMenu = '首页';
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
  }
</script>

<style lang="less" scoped>
  #box{
    width: 7.50rem;
    margin: 0 auto;
    background-color: #121212;
  }
  #drawer{
    height: 100%;
  }
  .logo_img {
    height: 0.88rem;
    float: left;
    cursor: pointer;
  }

  #back_to_top {
    position: fixed;
    bottom: 50px;
    right: 30px;
    cursor: pointer
  }

  .header-title {
    font-size: 1em;
  }

  .company_name {
    font-weight: normal;
    font-size: 1em;
  }

  .footer_font {
    font-size: 1em;
  }

  #menu_index {
    height: 100%;
  }
  /deep/ .el-menu--horizontal{
    background-color: #121212;
  }
  /deep/ .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
    background-color: #121212;
  }

  .header {
    margin: auto;
    width: 100%;
    height: 0.88rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 0.4rem;
  }
  .header-logo{
    height: 0.88rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-logo img{
    width: 1.66rem;
    height: auto;
  }
  .menuImg{
    width: 0.4rem;
    height: auto;
  }
  .header-title:focus {
    outline: none;
  }
  .navBarWrap {
    width: 7.5rem;
    position:fixed;
    top:0;
    left: 50%;
    transform: translate(-50%,0);
    z-index:999;
    background-color: #121212;
    // transition: all .5s;
    .header{
      height: 100%;
    }
  }
  // 点击展开后的menu
  .menuBox{
    height: auto;
    .logoBox{
      width: 100%;
      height: 0.88rem;
      background-color: #121212;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img{
        margin-left: 0.4rem;
        width: 1.66rem;
        height: auto;
      }
    }
    ul{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      li{
        width: 100%;
        height: 1.1rem;
        line-height: 1.1rem;
        border-bottom: 0.02rem solid #eeeeee;
        box-sizing: border-box;
        padding-left: 0.4rem;
        cursor: pointer;
        font-size: 0.28rem;
      }
      .menuClassRed{
        color: #d52226;
      }
    }
  }
  // 设置vantui中的menu导航栏中的关闭按钮位置
  /deep/ .van-popup__close-icon--top-right{
    top: 10px !important;
  }
</style>